/* src/components/Contact.css */

.contact-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9; /* Light background for the contact section */
}

.contact-section h2 {
  font-size: 3rem;
  color: #333; /* Dark text color for the heading */
  margin-bottom: 2rem;
}

iframe {
  border: none; /* Remove iframe border */
  max-width: 100%; /* Ensure the form is responsive */
  height: 600px; /* Set a reasonable height */
}
