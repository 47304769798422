/* src/App.css */

body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f0f8ff; /* Light background color */
}

.navbar {
  background-color: #fff;
  position: fixed;
  width: 100%;
  padding: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.navbar-container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}

.navbar-links a {
  margin: 0 15px;
  color: #333;
  text-decoration: none;
  font-weight: 500;
}

.home {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding: 0 10%;
}

.home-content {
  max-width: 600px;
}

.home-illustration img {
  max-width: 100%;
  height: auto;
}

.resume-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}
