/* src/components/Projects.css */
.projects-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .project-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .project-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .project-card p {
    margin-bottom: 10px;
  }
  
  .project-links a {
    margin-right: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
