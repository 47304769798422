/* src/components/Skills.css */

.skills-section {
  padding: 60px 20px;
  background-image: url('../assets/cloudBg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  text-align: center;
}

.skills-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.skill-category {
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
}

.skills-section h2 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
}

.skill-category h3 {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 2;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.skill-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.skill-icon {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #007bff;
  transition: color 0.3s;
}

.skill-item h4 {
  font-size: 1.2rem;
  color: #0e1013;
  margin-top: 5px;
  transition: color 0.3s;
}

.skill-item:hover .skill-icon,
.skill-item:hover h4 {
  color: #151618;
}
