/* src/components/About.css */
.about-section {
    padding: 60px 20px;
    background-color: #f9f9f9; /* Light background color */
    text-align: center;
  }
  
  .about-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  